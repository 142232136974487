// 权限管理路由
const activity: any = [
    {
        path: 'activity/activity',
        name: 'applet_activity',
        meta: {
            title: '活动列表'
        },
        component: () => import('@/views/activity/activity.vue')
    },
    {
        path: 'activity/activityAdd',
        name: 'activityAdd',
        meta: {
            pathTitle: 'applet_activity',
            title: '新建活动'
        },
        component: () => import('@/views/activity/activityAdd.vue')
    },
    {
        path: 'activity/activityEdit',
        name: 'activityEdit',
        meta: {
            pathTitle: 'applet_activity',
            title: '编辑活动'
        },
        component: () => import('@/views/activity/activityEdit.vue')
    },
    {
        path: 'activity/prizeDraw',
        name: 'applet_activity_prize',
        meta: {
            title: '抽奖活动'
        },
        component: () => import('@/views/activity/prizeDraw.vue')
    },
    {
        path: 'activity/prizeDrawEdit',
        name: 'prizeDrawEdit',
        meta: {
            pathTitle: 'applet_activity_prize',
            title: '编辑抽奖活动'
        },
        component: () => import('@/views/activity/prizeDrawEdit.vue')
    },
    {
        path: 'activity/prizeList',
        name: 'prizeList',
        meta: {
            pathTitle: 'applet_activity_prize',
            title: '抽奖名单'
        },
        component: () => import('@/views/activity/prizeList.vue')
    },
    {
        path: 'activity/jackpot',
        name: 'jackpot',
        meta: {
            pathTitle: 'applet_activity_prize',
            title: '奖池'
        },
        component: () => import('@/views/activity/jackpot.vue')
    },
]
export default activity
