// 权限管理路由
const general: any = [
    {
        path: 'channel/list',
        name: 'applet_channel_api',
        meta: {
            pathTitle: 'channel_manage'
        },
        component: () => import('@/views/channel/list.vue')
    },
    {
        path: 'channel/config',
        name: 'channel_manage_product',
        meta: {
            pathTitle: 'channel_manage_product',
            title: '产品列表'
        },
        component: () => import('@/views/channel/config.vue')
    },
    {
        path: 'channel/customer',
        name: 'accountCustomer',
        meta: {
            pathTitle: 'accountCustomer',
            title: '客户列表'
        },
        component: () => import('@/views/channel/customer.vue')
    },
    {
        path: 'channel/abnormal',
        name: 'applet_e_data_api',
        meta: {
            pathTitle: 'applet_e_data_api',
            title: '异常数据列表'
        },
        component: () => import('@/views/channel/abnormalList.vue')
    },
    {
        path: 'channel/channelList',
        name: 'applet_data_api',
        meta: {
            pathTitle: 'applet_data_api',
            title: '数据统计'
        },
        component: () => import('@/views/channel/channelList.vue')
    },
    {
        path: 'channel/black',
        name: 'applet_black_api',
        meta: {
            pathTitle: 'applet_black_api',
            title: '黑名单'
        },
        component: () => import('@/views/channel/black.vue')
    },
    {
        path: 'channel/query',
        name: 'applet_query_order',
        meta: {
            pathTitle: 'applet_query_order',
            title: '查询订单'
        },
        component: () => import('@/views/channel/query.vue')
    },
]
export default general
